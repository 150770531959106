import { API } from "../../../utils";

export const getFuturePrice = async (query) => {
  const res = await API.get("future-price", query);
  return res.data;
};
export const createFuturePrice = async (payload) => {
  const res = await API.post(`future-price`, payload);
  return res.data;
};
export const getFuturePriceDetails = async (id) => {
  const res = await API.get(`future-price/${id}`);
  return res.data;
};

export const editFuturePrice = async (id, payload) => {
  const res = await API.put(`future-price/${id}`, payload);
  return res.data;
};

export const deleteFutrePrice = async (id) => {
  const res = await API.delete(`future-price/${id}`);
  return res.data;
};

export const importFuturePrice = async (payload) => {
  const res = await API.post(`future-price/import-future-price`, payload);
  return res.data;
};

export const getFilteredProducts = async (query) => {
  const res = await API.get("product/list", query);
  return res.data;
};

export const downloadTemplate = async () => {
  const res = await API.get("export/future_price", {
    responseType: "blob",
  });
  return res;
};
