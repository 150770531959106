import React from "react";

import {
  Stack,
  Card,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

/**
 * CustomDetailsList component displays a list of details with a title and optional header content.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.columns - An array of column definitions, where each object contains:
 *  - {string|function} accessor - The key or function to access data.
 *  - {boolean|function} [hidden] - A flag or function to determine if the column should be hidden.
 *  - {string|ReactNode} [header] - Optional header content for the column.
 * @param {Array} props.data - The data to be displayed in the list.
 * @param {string} props.title - The title to display above the list.
 * @param {ReactNode} [props.headerRightContent] - Optional content to display on the right side of the header.
 * @param {string} [props.marginBottom] - The bottom margin of the Card component (default: "24px").
 * @param {Object} [props.style] - Custom styles for the Card component.
 * @param {Object} [props.headerStyle] - Custom styles for the header Stack component.
 * @param {ReactNode} [props.customHeader] - Optional custom header to replace the default header.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const CustomDetailsList = ({
  columns,
  data,
  title,
  headerRightContent = null,
  marginBottom = "24px",
  style = {},
  headerStyle = {},
  customHeader = null,
}) => {
  // const {
  //   columns,
  //   data,
  //   title,
  //   headerRightContent = null,
  //   marginBottom = "24px",
  //   style = {},
  //   headerStyle = {},
  // } = props;

  const prepareData = columns?.map((val) => {
    if (typeof val.accessor === "string") {
      return {
        ...val,
        accessor: data[val.accessor],
        hidden: val.hidden ? val.hidden(data) : false,
      };
    } else {
      return {
        ...val,
        accessor: val.accessor(data),
        hidden: val.hidden ? val.hidden(data) : false,
      };
    }
  });

  return (
    <Card sx={{ mb: marginBottom, ...style }}>
      {customHeader === null && title && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="83px"
          p="32px 24px"
          sx={headerStyle}
        >
          <Typography
            variant="subtitle1"
            fontWeight={600}
            lineHeight="19px"
            minWidth="160px"
          >
            {title}
          </Typography>
          {headerRightContent}
        </Stack>
      )}
      {customHeader && customHeader}

      <Divider />
      <List disablePadding>
        {prepareData?.map((data, index) => {
          return (
            <React.Fragment key={index}>
              {data.header ? data.header(data) : null}
              <ListItem
                divider={true}
                disablePadding
                key={index}
                sx={{
                  display: data.hidden ? "none" : "flex",
                  backgroundColor: data.backgroundColor,
                }}
              >
                <ListItemText
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    my: 0,
                    pl: 2,
                  }}
                  disableTypography={true}
                >
                  <Stack minHeight="48px" justifyContent="center" p="10px">
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      minWidth="160px"
                    >
                      {data.title}
                    </Typography>
                  </Stack>
                  <Stack
                    minHeight="48px"
                    justifyContent="center"
                    p="10px"
                    width="100%"
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      color="#636363"
                    >
                      {data.accessor}
                    </Typography>
                  </Stack>
                </ListItemText>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Card>
  );
};
