import React from "react";
import { CustomDetailsList, Loader } from "../../../shared";
import { Stack, Typography, useTheme } from "@mui/material";

/**
 * LoyaltyCardMergeColumn component displays a column for loyalty card merging.
 * It shows a primary title, optional header, and lists personal and card details.
 *
 * @param {Object} props - The component props.
 * @param {string|null} props.primaryTitle - The main title for the column.
 * @param {JSX.Element|null} props.header - Custom header element if provided.
 * @param {Object} props.personalDetails - Details related to personal information.
 * @param {Object} props.cardDetails - Details related to card information.
 * @param {boolean|null} props.loading - Flag indicating if data is loading.
 * @returns {JSX.Element} The rendered column component.
 */
export const LoyaltyCardMergeColumn = ({
  primaryTitle = null,
  header = null,
  personalDetails,
  cardDetails,
  loading = null,
}) => {
  const { palette } = useTheme();
  const list = { personalDetails, cardDetails };

  return (
    <Stack width="100%" display="flex" flexDirection="row">
      <Stack width="100%">
        <Stack
          height="95px"
          justifyContent="center"
          p="24px"
          sx={{ background: palette.gray[90] }}
        >
          {/* Render primary title or custom header */}
          {!header
            ? primaryTitle && (
                <Typography fontWeight={600} fontSize="16px">
                  {primaryTitle}
                </Typography>
              )
            : header}
        </Stack>
        {loading ? (
          // Display loader while data is loading
          <Stack minHeight="300px" width="100%" justifyContent="center">
            <Loader />
          </Stack>
        ) : (
          // Map through personal and card details
          Object.values(list).map(({ title, columns, data }) => {
            return (
              <CustomDetailsList
                key={title}
                columns={columns}
                data={data}
                customHeader={
                  <Stack
                    sx={{
                      backgroundColor: palette.gray[97],
                      height: "41px",
                      justifyContent: "center",
                      p: "24px 24px",
                    }}
                  >
                    {title && <Typography fontWeight={600}>{title}</Typography>}
                  </Stack>
                }
                style={{ borderRadius: "0", width: "100%" }}
              />
            );
          })
        )}
      </Stack>
    </Stack>
  );
};
