import { useImmerReducer } from "use-immer";
import { tableScopeReducer } from "../../actions/tableScopeActions";
import { useEffect } from "react";

const initialState = {
  initialLoad: true,
  params: {
    dashboardDateRange: {},
    suburbData: {
      filterApplied: {
        isSearching: false,
        search: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    stateData: {
      filterApplied: {
        isSearching: false,
        searchText: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      // sortBy: [],
    },
    orders: {
      filterApplied: {
        date: {},
        type: [],
        stores: [],
        status: [],
        searchText: null,
        channel: [],
        platform: [],
        payment: [],
        customer_type: [],
        terminal: [],
        feature_order: 0,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        total: 0,
        lastPage: 1,
      },
      sortBy: [],
      columnOptions: [],
    },
    discountedOrders: {
      filterApplied: {
        date: {},
        type: [],
        stores: [],
        status: [],
        searchText: null,
        channel: [],
        platform: [],
        payment: [],
        customer_type: [],
        terminal: [],
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        total: 0,
        lastPage: 1,
      },
      sortBy: [],
      columnOptions: [],
    },
    customers: {
      filterApplied: {
        isSearching: false,
        searchText: "",
        gender: "",
        status: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
      type: 0,
      columnOptions: [],
    },
    superProducts: {
      filterApplied: {
        categories: [],
        product_types: [],
        bump_screen_tags: [],
        status: {},
        all: [],
        searchText: null,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    storeProducts: {
      filterApplied: {
        categories: [],
        priceLevels: [],
        all: [],
        searchText: null,
      },
      columnOptions: [],
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    refunds: {
      filterApplied: {
        all: [],
        searchText: "",
        stores: [],
        date: [],
        channel: [],
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        total: 0,
        lastPage: 1,
      },
      sortBy: [],
      columnOptions: [],
    },
    storerefunds: {
      filterApplied: {
        all: [],
        searchText: "",
        stores: [],
        date: [],
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        total: 0,
        lastPage: 1,
      },
      sortBy: [],
      columnOptions: [],
    },
    wastage: {
      filterApplied: {
        isSearching: false,
        searchText: "",
        wastageType: "",
        date: [],
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
      columnOptions: [],
    },
    coupon: {
      filterApplied: {
        isSearching: false,
        searchText: "",
        status: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    birthdaycoupon: {
      filterApplied: {
        isSearching: false,
        searchText: "",
        status: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    eod: {
      filterApplied: {
        isSearching: false,
        all: [],
        searchText: "",
        stores: [],
        terminals: [],
        status: "",
        date: {},
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    couponReport: {
      filterApplied: {
        isSearching: false,
        date: {},
        type: [],
        stores: [],
        coupon: [],
        all: [],
        searchText: null,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    terminals: {
      filterApplied: {
        isSearching: false,
        all: [],
        searchText: "",
        stores: [],
        status: {},
        pairing_status: {},
      },
    },
    allCards: {
      filterApplied: {
        isSearching: false,
        all: [],
        searchText: "",
        batch_name: "",
        card_type: "",
        expired_on: "",
        status: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    PreloadedCard: {
      filterApplied: {
        isSearching: false,
        all: [],
        searchText: "",
        created_date: "",
        amount_limit_operator: "",
        amount_limit_value: "",
        validity_limit_value: "",
        validity_limit_operator: "",
        validity_limit_value: "",
        created_by: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    OpenCard: {
      filterApplied: {
        isSearching: false,
        all: [],
        searchText: "",
        created_date: "",
        amount_limit_operator: "",
        amount_limit_value: "",
        validity_limit_value: "",
        validity_limit_operator: "",
        validity_limit_value: "",
        created_by: "",
      },
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    confirmorders: {
      filterApplied: {
        date: {},
        type: [],
        stores: [],
        status: [],
        searchText: null,
        feature_order: 0,
        channel: [],
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        total: 0,
        lastPage: 1,
      },
      type: 0,
      sortBy: [],
      columnOptions: [],
    },
    xeroHistory: {
      filterApplied: {
        syncDate: {},
        journalDate: {},
        status: [],
        searchText: null,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        total: 0,
        lastPage: 1,
      },
      sortBy: [],
    },
    myobHistory: {
      filterApplied: {
        syncDate: {},
        journalDate: {},
        status: [],
        searchText: null,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        total: 0,
        lastPage: 1,
      },
      sortBy: [],
    },
    discount: {
      filterApplied: {
        all: [],
        searchText: "",
        stores: [],
        locked: "",
      },
    },
    clientAccount: {
      filterApplied: {
        isOpen: false,
        isSearching: false,
        all: [],
        searchText: "",
        stores: [],
        status: "",
        credit_limit_operator: "",
        due_amount_operator: "",
        credit_limit_value: "",
        due_amount_value: "",
      },
    },
    store: {
      filterApplied: {
        isSearching: false,
        searchText: "",
        status: "",
      },
    },
    superUser: {
      filterApplied: {
        stores: [],
        status: {},
        all: [],
        searchText: null,
      }
    },
    storeAdmin: {
      filterApplied: {
        status: null,
        searchText: null,
      }
    },
  },
};
export const useTableScope = () => {
  const [filterState, filterStateDispatch] = useImmerReducer(
    tableScopeReducer,
    initialState
  );

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("tableParams"));
    if (data) {
      filterStateDispatch({
        type: "UPDATE_INITIAL_DATA",
        data,
      });
    } else {
      filterStateDispatch({
        type: "UPDATE_INITIAL_LOAD",
      });
    }
  }, []);

  useEffect(() => {
    if (!filterState.initialLoad) {
      sessionStorage.setItem("tableParams", JSON.stringify(filterState.params));
    }
  }, [filterState.params]);

  const resetTableData = () => {
    filterStateDispatch({
      type: "RESET_STATE",
      data: initialState.params,
    });
  };

  return { filterState, filterStateDispatch, initialState, resetTableData };
};
