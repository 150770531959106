/* eslint-disable no-throw-literal */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../store";
import React from "react";
import { useImmer } from "use-immer";
import {
  getLoyaltyCustomerDetails,
  getLoyaltyCustomersList,
  mergeLoyaltyCardApi,
} from "../api";
import { errorMessage, successMessage } from "../../../utils";
import { useDebouncedCallback } from "use-debounce";

// Default pagination and account details
const defaultPagination = {
  pageIndex: 1,
  pageSize: 10,
  pageCount: 0,
  lastPage: 0,
  total: 0,
  hasMorePages: true,
};

const defaultAccountDetails = {
  personal: {
    id: null,
    customer_name: "",
    customer_verified: null,
    customer_email: "",
    customer_phone: "",
    date_of_birth: "",
    gender: "",
    opt_in: null,
  },
  card: {
    barcode: "",
    member_number: "",
    verification_code: "",
    status: null,
    points: null,
    points_in_dollars: "$0",
  },
};

/**
 * Custom hook for handling loyalty card merging functionality.
 */
export const useLoyaltyCardMerge = () => {
  const { id } = useParams(); // Get the customer ID from the URL parameters
  const navigate = useNavigate();

  const {
    appState: { globalData },
  } = React.useContext(AppContext); // Access global app context

  // State management using immer for immutability
  const [state, setState] = useImmer({
    targetAccountDetails: defaultAccountDetails,
    mergeAccountDetails: defaultAccountDetails,
    finalMerge: defaultAccountDetails,
    loading: {
      targetAccountDetails: true,
      mergeAccountDetails: false,
      finalMerge: false,
    },
    mergePersonalDetails: false,
    selectedMemberDetails: null,
    memberList: {
      search: "",
      options: [],
      loading: true,
      pagination: defaultPagination,
    },
    mergeButtonLoading: false,
  });

  // Breadcrumb links for navigation
  const breadcrumbsLinks = React.useMemo(
    () => [
      { name: "Dashboard", href: "/" },
      {
        name: "Loyalty Cards",
        href: "/loyalty-cards",
      },
      {
        name: "Cards",
        href: `/loyalty-cards?cards`,
      },
      {
        name: state.targetAccountDetails.personal.customer_name,
        href: "/loyalty-cards/details/".concat(id),
      },
      { name: "Merge" },
    ],
    [state.targetAccountDetails.personal.customer_name]
  );

  // ================================================================== API SECTION =========================================================
  /**
   * Fetch loyalty customer details by ID.
   * @async
   * @function getLoyaltyCustomerDetailsById
   * @param {Object} params - Parameters for the fetch operation.
   * @param {string} params.customerId - The ID of the customer to fetch.
   * @param {string} params.type - The type of account details to update.
   */
  const getLoyaltyCustomerDetailsById = async ({ customerId, type }) => {
    triggerLoading({ loadingState: type, status: true });
    type === "mergeAccountDetails" &&
      triggerLoading({ loadingState: "finalMerge", status: true });
    try {
      const response = await getLoyaltyCustomerDetails(customerId);
      const { success, data, message } = response;
      if (success) {
        const {
          // Personal Details
          id,
          customer_name,
          customer_verified,
          customer_email,
          customer_phone,
          date_of_birth,
          gender,
          opt_in,
          // Card details
          barcode,
          member_number,
          verification_code,
          status,
          points,
          points_in_dollars,
        } = data;

        const personal = {
          id,
          customer_name,
          customer_verified,
          customer_email,
          customer_phone,
          date_of_birth,
          gender,
          opt_in,
        };

        const card = {
          barcode,
          member_number,
          verification_code,
          status,
          points,
          points_in_dollars,
        };

        setState((draft) => {
          const enablesPersonalDetailsMerge = draft.mergePersonalDetails;

          if (type === "mergeAccountDetails") {
            const targetAccountDetails = draft.targetAccountDetails;
            const mergeAccountDetails = { personal, card };
            const actualTargetAccountDetailsCardPoints =
              targetAccountDetails.card.points / 100;
            const actualCardPoints = card.points / 100;
            const newPoints =
              actualTargetAccountDetailsCardPoints + actualCardPoints;

            draft.finalMerge = updateAccountDetailsWithNewPoints({
              accountDetails: enablesPersonalDetailsMerge
                ? mergeAccountDetails
                : targetAccountDetails,
              newPoints,
            });
          }
          draft[type].card = card;
          draft[type].personal = personal;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        "Oops, Failed to fetch customer details";
      errorMessage(message);
    } finally {
      triggerLoading({ loadingState: type, status: false });
      type === "mergeAccountDetails" &&
        triggerLoading({ loadingState: "finalMerge", status: false });
    }
  };

  /**
   * Fetch the customer list with pagination and search parameters.
   *
   * @async
   * @function getCustomerList
   * @param {Object} params - Parameters for the fetch operation.
   * @param {number} params.pageIndex - The index of the page to fetch.
   * @param {number} params.pageSize - The size of the page to fetch.
   * @param {string} [params.search=""] - Optional search term.
   */
  const getCustomerList = async ({ pageIndex, pageSize, search = "" }) => {
    const params = {};
    const pagination = { pageIndex, pageSize };
    params["loyalty_card_type[]"] = 1; // Only fetch loyalty card type 1
    if (search.trim()) params["search"] = search; // Add search param if provided

    triggerMemberListLoading(true);
    try {
      const response = await getLoyaltyCustomersList({ params }, pagination);
      const { success, data, message } = response;
      if (success) {
        const { loyalty_cards, pagination } = data;
        const memberList = loyalty_cards.map(
          ({ id, customer_id, customer_name, member_number }) => {
            return { id, customer_id, customer_name, member_number };
          }
        );

        setState((draft) => {
          const newOptions = draft.memberList.options.concat(memberList);
          const uniqueOptions = newOptions.filter(
            (item, index, array) =>
              index === array.findIndex((i) => i.id === item.id)
          );
          draft.memberList.options = uniqueOptions;
          draft.memberList.pagination = pagination;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        "Oops, Failed to fetch the customer details";
      errorMessage(message);
    } finally {
      triggerMemberListLoading(false);
    }
  };

  /**
   * Merges a loyalty card for the specified account.
   * Triggers loading state during the operation and handles success or error messages.
   *
   * @async
   * @function mergeLoyaltyCard
   * @param {Object} params - The parameters for merging the loyalty card.
   * @param {number} params.accountId - The ID of the account to merge the loyalty card with.
   * @param {number} params.source_card_id - The ID of the source card to merge.
   * @param {boolean} params.merge_personal_details - Indicates whether to merge personal details.
   *
   */
  const mergeLoyaltyCard = async ({
    accountId,
    source_card_id,
    merge_personal_details,
  }) => {
    triggerMergeButtonLoading(true);
    try {
      const response = await mergeLoyaltyCardApi({
        accountId,
        source_card_id,
        merge_personal_details,
      });
      const { success, message } = response;
      if (success) {
        successMessage(message);
        navigate(`/loyalty-cards/details/${id}`);
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Oops, Failed to merge loyalty cards";
      errorMessage(message);
    } finally {
      triggerMergeButtonLoading(false);
    }
  };

  // =================================================================== UTIL FUNCTION SECTION ================================================

  /**
   * Trigger loading state for merge button.
   *
   * @param {boolean} status - The loading status to set.
   */
  const triggerMergeButtonLoading = (status) => {
    setState((draft) => {
      draft.mergeButtonLoading = status;
    });
  };

  /**
   * Trigger loading state for member list.
   *
   * @param {boolean} status - The loading status to set.
   */
  const triggerMemberListLoading = (status) => {
    setState((draft) => {
      draft.memberList.loading = status;
    });
  };

  /**
   * Trigger loading state for a specific section.
   *
   * @param {Object} params - Parameters for the loading state.
   * @param {string} params.loadingState - The state to update.
   * @param {boolean} params.status - The loading status to set.
   */
  const triggerLoading = ({ loadingState, status }) => {
    setState((draft) => {
      draft.loading[loadingState] = status;
    });
  };

  /**
   * Find status details based on the given status and boot data.
   *
   * @param {string} status - The status to look for.
   * @param {Object} bootData - The boot data to search through.
   * @returns {Object|null} - The found status details or null.
   */
  const findStatusDetails = (status, bootData) => {
    const statusDetails = bootData?.status?.find(
      (item) => item.value === status
    );
    return statusDetails ?? null;
  };

  /**
   * Handle fetching customer list with current pagination and search key.
   *
   * @param {number} page - The page index to fetch.
   * @param {string} searchKey - The search key for filtering results.
   */
  const handleCustomerListFetch = async (page, searchKey) => {
    const {
      search,
      pagination: { pageSize, pageIndex },
    } = state.memberList;
    await getCustomerList({
      pageSize,
      pageIndex: page ?? pageIndex,
      search: searchKey ?? search,
    });
  };

  /**
   * Handle customer search input changes.
   *
   * @param {Object} event - The event object from the input change.
   * @param {string} inputValue - The current input value.
   */
  const handleCustomerSearch = async (event, inputValue) => {
    const { type } = event;

    if (type === "change" || inputValue === "") {
      const searchKey = inputValue;
      updateSearchState(inputValue);

      const currentPage = defaultPagination.pageIndex;

      await debouncedCustomerSearching(currentPage, searchKey);
    }
  };

  /**
   * Update the search state with the new search term.
   *
   * @param {string} [search=""] - The search term to update.
   */
  const updateSearchState = (search = "") => {
    setState((draft) => {
      draft.memberList.search = search;
      draft.memberList.options = [];
      draft.memberList.pagination = defaultPagination;
    });
  };

  // Debounced fetching for more members
  const fetchMoreMembers = useDebouncedCallback(
    (index) => handleCustomerListFetch(index),
    // Wait 1 second after the last call
    1000
  );

  // Debounced searching for customers
  const debouncedCustomerSearching = useDebouncedCallback(
    (currentPage, search) => handleCustomerListFetch(currentPage, search),
    // Wait 1 second after the last call
    1000
  );

  /**
   * Handle customer selection from the list.
   *
   * @param {Object} _ - Unused parameter.
   * @param {Object} customerDetails - The selected customer details.
   */
  const handleCustomerSelection = (_, customerDetails) => {
    const hasSelectedCustomer = Boolean(customerDetails?.id);

    if (!hasSelectedCustomer) {
      triggerLoading({ loadingState: "mergeAccountDetails", status: true });
      triggerLoading({ loadingState: "finalMerge", status: true });
    }

    setState((draft) => {
      if (!hasSelectedCustomer) {
        draft.finalMerge = defaultAccountDetails;
        draft.mergeAccountDetails = defaultAccountDetails;
      }
      draft.selectedMemberDetails = customerDetails;
    });

    if (hasSelectedCustomer) {
      getLoyaltyCustomerDetailsById({
        customerId: customerDetails.id,
        type: "mergeAccountDetails",
      });
    } else {
      triggerLoading({ loadingState: "mergeAccountDetails", status: false });
      triggerLoading({ loadingState: "finalMerge", status: false });
    }
  };

  /**
   * Update account details with new points value.
   *
   * @param {Object} params - Parameters for updating account details.
   * @param {Object} params.accountDetails - The account details to update.
   * @param {number} params.newPoints - The new points to set.
   * @returns {Object} - The updated account details.
   */
  const updateAccountDetailsWithNewPoints = ({ accountDetails, newPoints }) => {
    const newPointsInDollars = "$".concat(newPoints.toFixed(2));
    const updatedDetails = {
      ...accountDetails,
      card: {
        ...accountDetails.card,
        points: newPoints,
        points_in_dollars: newPointsInDollars,
      },
    };
    return updatedDetails;
  };

  /**
   * Handle the change of merging personal details.
   *
   * @param {Object} _ - Unused parameter.
   * @param {boolean} checked - The checked state of the merge option.
   */
  const handleChangeMergePersonalDetails = (_, checked) => {
    setState((draft) => {
      const mergeAccountDetails = draft.mergeAccountDetails;
      const targetAccountDetails = draft.targetAccountDetails;
      const isMergeTargetAccountSelected = draft.selectedMemberDetails;

      if (isMergeTargetAccountSelected) {
        const newPoints =
          targetAccountDetails.card.points / 100 +
          mergeAccountDetails.card.points / 100;

        draft.finalMerge = updateAccountDetailsWithNewPoints({
          accountDetails: checked ? mergeAccountDetails : targetAccountDetails,
          newPoints,
        });
      }
      draft.mergePersonalDetails = checked;
    });
  };

  /**
   * Handles the process of merging a loyalty card for the current account.
   * Retrieves necessary data from the state and calls the mergeLoyaltyCard function.
   */
  const handleMergeLoyaltyCard = async () => {
    const { mergeAccountDetails, targetAccountDetails, mergePersonalDetails } =
      state;
    const accountId = targetAccountDetails.personal.id;
    const sourceCardId = mergeAccountDetails.personal.id;

    if (accountId && sourceCardId) {
      await mergeLoyaltyCard({
        accountId: accountId,
        source_card_id: sourceCardId,
        merge_personal_details: mergePersonalDetails,
      });
    } else {
      errorMessage("The target account ID or source card ID is missing");
    }
  };

  // Fetch initial data on component mount
  React.useEffect(() => {
    getLoyaltyCustomerDetailsById({
      customerId: id,
      type: "targetAccountDetails",
    });
    handleCustomerListFetch();
  }, []);

  // Return state and helper functions
  return {
    breadcrumbsLinks,
    state,
    globalData,
    findStatusDetails,
    fetchMoreMembers,
    handleCustomerSearch,
    handleMergeLoyaltyCard,
    handleCustomerSelection,
    handleChangeMergePersonalDetails,
  };
};
