import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import {
  LoyalityCustomerDetails,
  LoyaltyAddorEditCustomer,
  LoyaltyCardsDetails,
  LoyaltyPointHistoryDetails,
} from "./components";
import { LoyaltyList } from "./containers/LoyaltyList";
import {
  LoyaltyAddorEditSponsorshipGroup,
  LoyaltyCardMerge,
  LoyaltySponsorshipGroupsDetails,
} from ".";

export const Loyalty = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoyaltyList />} />

        <Route
          element={<ProtectedRoute allowedRoute={["loyalty-cards-create"]} />}
        >
          <Route path="/create" element={<LoyaltyAddorEditCustomer />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["loyalty-cards-view"]} />}
        >
          <Route path="/details/:id" element={<LoyalityCustomerDetails />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["loyalty-cards-modify"]} />}
        >
          <Route path="/edit/:id" element={<LoyaltyAddorEditCustomer />} />
          <Route path="/details/:id/merge" element={<LoyaltyCardMerge />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["loyalty-cards-view"]} />}
        >
          <Route path="/types/:id" element={<LoyaltyCardsDetails />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["loyalty-cards-view"]} />}
        >
          <Route
            path="/details/:cusID/pointTransaction/:id"
            element={<LoyaltyPointHistoryDetails />}
          />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["loyalty-cards-view"]} />}
        >
          <Route
            path="/pointhistory/:id"
            element={<LoyaltyPointHistoryDetails />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute allowedRoute={["loyalty-card-groups-create"]} />
          }
        >
          <Route
            path="/sponsorship-group/create"
            element={<LoyaltyAddorEditSponsorshipGroup />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute allowedRoute={["loyalty-card-groups-view"]} />
          }
        >
          <Route
            path="/sponsorship-group/:id"
            element={<LoyaltySponsorshipGroupsDetails />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute allowedRoute={["loyalty-card-groups-modify"]} />
          }
        >
          <Route
            path="/sponsorship-group/:id/edit"
            element={<LoyaltyAddorEditSponsorshipGroup />}
          />
        </Route>
      </Routes>
    </>
  );
};
