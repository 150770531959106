import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  usePermission,
  CheckBoxListDialog,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { sortItems } from "../../../utils";
import { useNavigate } from "react-router-dom";

export const RolesList = (props) => {
  const navigate = useNavigate();
  const isEditable = usePermission("user-modify");
  const isDeletable = usePermission("user-delete");
  const {
    title,
    type,
    rolesDetails,
    handleModal,
    HandleFilterRoles,
    handleRolesSelect,
    handleAssignRolestoUser,
    handleDeleteRole,
    isStoreAdmin,
    setCurentTab,
    handleSelectAllRoles,
  } = props;

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Role Name",
        id: "name",
        accessor: "display_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() => handleModal("role-delete", true, row.id, type)}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomTable
        columns={columns}
        data={rolesDetails.rolesList}
        searchBar={false}
        tableTitle={title}
        rowsSelection={false}
        headerRightContent={
          <>
            {isEditable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                startIcon={<AddIcon />}
                onClick={() => handleModal("roles-add", true, null, type)}
              >
                Add Role
              </Button>
            )}
          </>
        }
        onRowLink={(e) => {
          return isStoreAdmin
            ? `/store-admin/roles-and-permissions/${type === "posRolesDetails" ? "pos/details" : "details"}/${e.id}`
            : `/roles-and-permissions/${type === "posRolesDetails" ? "pos/details" : "details"}/${e.id}`;
        }}
        onRowAction={(e) => {
          setCurentTab("rolesAndPermission", "1");
        }}
      />
      <CustomeDialog
        open={rolesDetails.isOpen}
        handleClose={() => handleModal("roles-add", false, null, type)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Roles"
        content={
          <CheckBoxListDialog
            Items={rolesDetails.filteredRoles}
            onSearch={HandleFilterRoles}
            loading={rolesDetails.isLoading}
            onSelect={handleRolesSelect}
            onSubmit={handleAssignRolestoUser}
            disabled={
              !rolesDetails.selectedRoles.length || rolesDetails.isSaveButton
            }
            isSelected={(id) => rolesDetails.selectedRoles.includes(id)}
            onClearSearch={() => HandleFilterRoles("initial")}
            value={rolesDetails.searchText}
            hasSelectAll={true}
            isSelectAllChecked={rolesDetails.filteredRoles.every((item) =>
              rolesDetails.selectedRoles.includes(item.id)
            )}
            onAllSelect={handleSelectAllRoles}
            valueKey="display_name"
          />
        }
      />
      <DeleteModal
        open={rolesDetails.isDelete}
        handleClose={() => handleModal("role-delete", false, null, type)}
        onConfirm={handleDeleteRole}
      />
    </>
  );
};
