/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLoyaltyCardMerge } from "../hooks";
import {
  Box,
  Card,
  Chip,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import infoIcon from "../../../assets/images/Icons/ic_info.svg";
import optInIcon from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutIcon from "../../../assets/images/Icons/ic_cancel-opt-out.svg";
import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import { LoyaltyCardMergeColumn } from "../components";
import InputControl from "../../../shared/components/InputControl";
import { LoadingButton } from "@mui/lab";

/**
 * LoyaltyCardMerge component handles the merging of loyalty card accounts.
 * It allows users to view and select members for merging, displaying their
 * personal and card details.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const LoyaltyCardMerge = () => {
  // Destructure state and helper functions from custom hook
  const {
    state,
    globalData,
    breadcrumbsLinks,
    fetchMoreMembers,
    findStatusDetails,
    handleCustomerSearch,
    handleMergeLoyaltyCard,
    handleCustomerSelection,
    handleChangeMergePersonalDetails,
  } = useLoyaltyCardMerge();

  // Destructure necessary state variables
  const {
    memberList,
    finalMerge,
    loading,
    mergeButtonLoading,
    selectedMemberDetails,
    mergePersonalDetails,
    targetAccountDetails,
    mergeAccountDetails,
  } = state;

  // Define columns for personal details table
  const personalDetailsColumns = React.useMemo(
    () => [
      {
        title: "Name",
        accessor: "customer_name",
      },
      {
        title: "Customer Verification",
        accessor: ({ customer_verified }) => {
          // Show verification badge if customer is verified
          return customer_verified ? (
            <Stack alignItems="center" flexDirection="row" gap="4px">
              <img
                style={{ height: "14px" }}
                alt="verify"
                src="/icons/ic_verified_badge.svg"
              />
              <Typography>Verified</Typography>
            </Stack>
          ) : (
            "--"
          );
        },
      },
      {
        title: "Email",
        accessor: "customer_email",
      },
      {
        title: "Phone Number",
        accessor: "customer_phone",
      },
      {
        title: "Date of Birth",
        accessor: "date_of_birth",
      },
      {
        title: "Gender",
        accessor: ({ gender }) => {
          return gender ? gender : "--";
        },
      },
      {
        title: "Opt In",
        accessor: ({ opt_in }) => {
          // Show opt-in status with appropriate icon
          return opt_in ? (
            <Box
              component="img"
              src={opt_in ? optInIcon : optOutIcon}
              width="16px"
              height="16px"
            />
          ) : (
            "--"
          );
        },
      },
    ],
    []
  );

  // Define columns for card details table
  const cardDetailsColumns = React.useMemo(
    () => [
      {
        title: "Barcode",
        accessor: "barcode",
      },
      {
        title: "Member Number",
        accessor: "member_number",
      },
      {
        title: "Verification Code",
        accessor: "verification_code",
      },
      {
        title: "Status",
        accessor: ({ status }) => {
          // Retrieve and display status label
          const statusDetails = findStatusDetails(status, globalData);
          return statusDetails ? (
            <Chip
              label={statusDetails.label}
              variant="filled"
              size="small"
              sx={{ backgroundColor: "#E1FBF2", color: "#0F875B" }}
            />
          ) : (
            "--"
          );
        },
      },
      {
        title: "Points",
        accessor: ({ points, points_in_dollars }) => {
          // Display points in dollars if available
          return points ? (
            <Typography color="#C07302" fontWeight={600}>
              {points_in_dollars}
            </Typography>
          ) : (
            "--"
          );
        },
      },
    ],
    [globalData]
  );

  return (
    <ContentLayout
      title="Merge"
      breadcrumbs={<HeaderBreadcrumbs links={breadcrumbsLinks} />}
      rightContent={
        <LoadingButton
          variant="contained"
          loading={mergeButtonLoading}
          onClick={handleMergeLoyaltyCard}
          disabled={!finalMerge.personal.customer_name}
        >
          Merge
        </LoadingButton>
      }
    >
      <Card
        sx={{
          overflow: "auto",
          ".MuiCard-root": {
            margin: 0,
          },
        }}
      >
        <Stack py="32px" px="24px" alignItems="end" justifyContent="center">
          <InputControl
            type="switch"
            label="Merge Personal Details"
            labelPlacement="start"
            checked={mergePersonalDetails}
            onChange={handleChangeMergePersonalDetails}
          />
        </Stack>

        <Stack flexDirection="row">
          {/* Target Account details column */}
          <LoyaltyCardMergeColumn
            primaryTitle="Target Account (Customer keeps)"
            loading={loading.targetAccountDetails}
            personalDetails={{
              columns: personalDetailsColumns,
              title: "Personal Details",
              data: targetAccountDetails.personal,
            }}
            cardDetails={{
              columns: cardDetailsColumns,
              title: "Card Details",
              data: targetAccountDetails.card,
            }}
          />

          {/* Merge account details column*/}
          <LoyaltyCardMergeColumn
            loading={loading.mergeAccountDetails}
            header={
              <Stack gap="8px">
                <InputControl
                  type="dropdown"
                  name="memberNumber"
                  label="Member Number"
                  options={memberList.options}
                  value={selectedMemberDetails}
                  loading={memberList.loading}
                  onChange={handleCustomerSelection}
                  getOptionLabel={(option) => option.member_number}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(getListitemProps, option) => {
                    return (
                      <React.Fragment key={option.id}>
                        <ListItem {...getListitemProps}>
                          <ListItemText
                            primary={option.member_number}
                            secondary={option.customer_name}
                          />
                        </ListItem>

                        {/* Showing load more option */}
                        {getListitemProps["data-option-index"] ===
                          memberList.options.length - 1 &&
                          memberList.pagination.hasMorePages && (
                            <Stack justifyContent="center" alignItems="center">
                              Loading...
                            </Stack>
                          )}
                      </React.Fragment>
                    );
                  }}
                  onInputChange={handleCustomerSearch}
                  inputLabel="Member Number"
                  placeholder="00000"
                  sx={{
                    background: "#ffffff",
                    borderRadius: 1,
                    outline: "#808080",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#808080",
                      },
                    },
                  }}
                  renderInput={(getTextfieldProps) => (
                    <TextField
                      {...getTextfieldProps}
                      label="Member Number"
                      placeholder="Member Number"
                    />
                  )}
                  ListboxProps={{
                    onScroll: (event) => {
                      const listboxNode = event.currentTarget;
                      const threshold = 5; // Adjust as needed
                      const { pagination } = memberList;

                      // Check if we need to load more members
                      if (
                        listboxNode.scrollHeight - listboxNode.scrollTop <=
                        listboxNode.clientHeight + threshold
                      ) {
                        const currentPage = pagination.pageIndex + 1;
                        if (
                          pagination.hasMorePages &&
                          currentPage <= pagination.lastPage
                        ) {
                          fetchMoreMembers(pagination.pageIndex + 1);
                        }
                      }
                    },
                  }}
                />
                <Stack flexDirection="row" alignItems="center" gap="4px">
                  <Box
                    component="img"
                    src={infoIcon}
                    alt="info icon"
                    width="12px"
                    height="12px"
                  />
                  <Typography
                    alignItems="center"
                    display="flex"
                    gap="8px"
                    fontSize="12px"
                    color="#636363"
                  >
                    Account to Merge (Will be deleted)
                  </Typography>
                </Stack>
              </Stack>
            }
            personalDetails={{
              columns: personalDetailsColumns,
              title: "Personal Details",
              data: mergeAccountDetails.personal,
            }}
            cardDetails={{
              columns: cardDetailsColumns,
              title: "Card Details",
              data: mergeAccountDetails.card,
            }}
          />

          {/* Merge Preview column*/}
          <LoyaltyCardMergeColumn
            loading={loading.finalMerge}
            primaryTitle="Final Merge (Preview)"
            personalDetails={{
              columns: personalDetailsColumns,
              title: "Personal Details",
              data: finalMerge.personal,
            }}
            cardDetails={{
              columns: cardDetailsColumns,
              title: "Card Details",
              data: finalMerge.card,
            }}
          />
        </Stack>
      </Card>
    </ContentLayout>
  );
};
