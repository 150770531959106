import { ContentLayout, Loader } from "../../../shared";
import * as React from "react";
import {
  Link,
  Chip,
  Stack,
  Switch,
  Typography,
  Tab,
  Box,
  Card,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { CustomTable } from "../../../shared";
import { useMenuLog } from "../hooks/useMenuLog";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const MenulogList = () => {
  const {
    state,
    handleAllMenu,
    handlePushMenu,
    handleUpdate,
    isEditable,
    currentTab,
    setCurentTab,
    handleInputChange,
    optionsMenuTemplate,
    handleSubmit,
  } = useMenuLog({ isList: true });

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const handleChange = (event, newValue) => {
    setCurentTab("menulog", newValue);
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const ActionButtons = ({ value, id }) => {
    return (
      <div className="action-buttons">
        {value.menulog_status === 1 && (
          <div onClick={() => handlePushMenu(value?.store_id)}>
            <Stack direction="row" className="hover-icon-link" gap={1}>
              <img src="/icons/icon_blue_refresh.svg" />
              <Link underline="none" component="button" variant="body2">
                <Typography fontWeight={600}>Push Menu</Typography>
              </Link>
            </Stack>
          </div>
        )}
        {isEditable && (
          <Switch
            checked={value?.menulog_status === 1}
            onClick={(e) => handleUpdate(value, e.target.checked)}
          />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store_name",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap={2}>
              <Typography>{row.original.store_name}</Typography>
              <Chip
                label={row.original.store_id}
                color="success"
                variant="outlined"
                size="small"
                disabled
                sx={{ mr: 1, borderRadius: "8px" }}
              />
            </Stack>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return <ActionButtons value={row.original} id={value} />;
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title="Menulog"
        rightContent={
          <>
            {currentTab === "1" ? (
              <LoadingButton
                type="button"
                size="medium"
                variant="contained"
                onClick={handleAllMenu}
                loading={state?.isAllSaveButton}
                startIcon={<img src="/icons/icon_refresh.svg" />}
              >
                Push Menu
              </LoadingButton>
            ) : (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={onSubmit}
                disabled={state.isSaveButtonBusy}
              >
                Save
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              top: "88px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Stores" value="1" />
              <Tab label="Settings" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <CustomTable
              columns={columns}
              data={state?.menuLogData}
              pagination={false}
              isLoading={state?.isBusy}
              tableId="menulog"
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              {validator.current.purgeFields()}
              {state.isBusy ? (
                <Loader />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ width: "150px" }}
                    >
                      Menu Template
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Stack width={"50%"} gap="20px">
                      <InputControl
                        type="dropdown"
                        disableClearable
                        options={optionsMenuTemplate ?? []}
                        getOptionLabel={(option) => option.label}
                        value={
                          (optionsMenuTemplate?.length > 0 &&
                            state.dataMenu.menu_id &&
                            optionsMenuTemplate?.find(
                              (c) => c.value === state.dataMenu.menu_id
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          handleInputChange({
                            target: {
                              name: "menu_id",
                              value: newValue.value,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Menu Template"
                            required
                            error={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Card>
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
