import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { usePermission, useTabs } from "../../../shared";
import { AppContext } from "../../../store/AppScopeProvider";
import { errorMessage, getStoreId, successMessage } from "../../../utils";
import moment from "moment";
import {
  assignCustomersToClinetAccount,
  createClientAccount,
  createClinetAccountSettlement,
  getClientAccountsList,
  getPurchasedData,
} from "../api";
import {
  createCustomer,
  deleteClientAccountById,
  EnableOrDisableClientAccount,
  getClientAccountDetailsById,
  getCustomersList,
} from "../../Customers";
import qs from "qs";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { AuthContext } from "../../../store/AuthProvider";
import { TableStateContext } from "../../../store";

export const useClientAccount = ({ isList, id, settlementId, isCreate }) => {
  const { appState } = useContext(AppContext);
  const storeId = getStoreId();
  const { auth } = useContext(AuthContext);
  const { setCurentTab } = useTabs();
  const isEditable = usePermission("client-account-modify");
  const isDeletable = usePermission("client-account-delete");
  const isCreateVisible = usePermission("client-account-create");
  const {
    globalData,
    currentTabs: { clientAccDetails: currentTab },
  } = appState;
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const isCustomer = pathname.includes("customers");
  const isDetalilsPage = pathname.includes("/details");

  const navigate = useNavigate();
  const [state, setState] = useImmer({
    initialLoad: true,
    filters: {
      isOpen: false,
      isSearching: false,
      all: [],
      searchText: "",
      stores: [],
      status: "",
      credit_limit_operator: "",
      due_amount_operator: "",
      credit_limit_value: "",
      due_amount_value: "",
    },
    filterApplied: {
      categories: [],
      status: {},
      all: [],
      searchText: null,
      isSearching: false,
    },
    clientAccount: {
      isLoading: true,
      initialLoad: true,
      dialogLoading: true,
      dataList: [],
      isOpen: false,
      isDeleteOpen: false,
      id: null,
      allDataList: [],
      filteredList: [],
      selectedItems: [],
      deleteModalOpen: false,
      selectedId: null,
      searchText: null,
    },
    customers: {
      isBusy: false,
      isLoading: true,
      list: [],
      optionsList: [],
      searchText: null,
      pagination: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        pageCount: null,
        hasMorePages: null,
        lastPage: null,
      },
    },
    clientAccountDetails: {
      isSaveButtonBusy: false,
      isLoading: true,
      details: {},
      updateData: {
        store_id: "",
        customer_id: [],
        credit_limit: null,
        restrict_usage: 1,
        bill_duration: null,
        bill_period: null,
        bill_day: null,
        bill_date: null,
        bill_date2: null,
        status: 0,
        carry_forward: 0,
      },
    },
    settlement: {
      isSaveButtonBusy: false,
      data: {
        amount: "",
        settlement_date: null,
        payment_type_id: "",
        select_type: 0,
        transactions: [],
      },
    },
    purchasedData: [],
    filterData: [],
    purchaseAmount: 0,
    amount: 0,
    storeFilteredClientAccounts: [],
  });

  const PAGE_NAME = "clientAccount";
  const {
    filterState: {
      params: { clientAccount },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);


  const [value, setValue] = React.useState(2);
  useEffect(() => {
    if (value === 1) {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_date2 = "";
        draft.clientAccountDetails.updateData.bill_day = "";
      });
    }
    if (value === 2) {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_date = "";
      });
    }
  }, [value]);
  useEffect(() => {
    if (isStoreAdmin && isCreate) {
      getClientAccountOnStore(storeId);
    }
  }, [storeId, isCreate]);
  useEffect(() => {
    if (state.clientAccountDetails.updateData.store_id) {
      getClientAccountOnStore(state.clientAccountDetails.updateData.store_id);
    }
  }, [state.clientAccountDetails.updateData.store_id, isCreate]);
  const [debouncedText] = useDebounce(state.customers.searchText, 1000);
  useEffect(() => {
    if (isList) {
      const {
        stores,
        status,
        searchText,
        credit_limit_operator,
        due_amount_operator,
        credit_limit_value,
        due_amount_value,
      } = clientAccount.filterApplied;
      setState((draft) => {
        draft.filters = clientAccount.filterApplied;
      });
      handleClientAccountList(stores,
        searchText,
        status,
        credit_limit_operator,
        due_amount_operator,
        credit_limit_value,
        due_amount_value);
    }

  }, [isList]);



  const getCustomerList = async ({ search = null, pagination, type = "" }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      if (type === "search") {
        setState((draft) => {
          draft.customers.isBusy = true;
        });
      }
      setState((draft) => {
        draft.customers.isLoading = true;
      });
      const res = await getCustomersList(query, pagination);
      if (res.success) {
        if (type === "search") {
          setState((draft) => {
            draft.customers.list = res.data.customers;
          });
        } else {
          setState((draft) => {
            draft.customers.list = [
              ...draft.customers.list,
              ...res.data.customers,
            ];
          });
        }
        setState((draft) => {
          draft.customers.isBusy = false;
          draft.customers.isLoading = false;
          draft.customers.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.customers.isBusy = false;
        draft.customers.isLoading = false;
      });
    }
  };

  useEffect(() => {
    if (!state.customers.isLoading) {
      const { pagination } = state.customers;
      getCustomerList({
        search: debouncedText,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        type: "search",
      });
    }
  }, [debouncedText]);

  const handleModal = (type, state, data) => {
    switch (type) {
      case "customer-add":
        if (!state) {
          setState((draft) => {
            draft.clientAccount.isOpen = state;
            draft.customers.searchText = null;
            draft.clientAccount.selectedItems = [];
          });
        } else {
          setState((draft) => {
            draft.clientAccount.isOpen = state;
          });
        }
        break;
      case "client-ac-delete":
        setState((draft) => {
          draft.clientAccount.isDeleteOpen = state;
          draft.clientAccount.id = data;
        });
        break;
      case "details":
        setState((draft) => {
          draft.clientAccount.isOpen = state;
          draft.clientAccount.id = data;
        });
        break;
    }
  };

  //Client Account

  const handleClientAccountList = async (
    stores = [],
    searchText = "",
    status = "",
    credit_limit_operator = "",
    due_amount_operator = "",
    credit_limit_value = "",
    due_amount_value = ""
  ) => {
    let params = {};
    let IDs = stores.map((v) => {
      return v.value;
    });
    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (status && toString(status.value)) {
      params = { ...params, status: status.value };
    }
    if (IDs.length > 0) {
      params = { ...params, store_id: IDs };
    }
    if (credit_limit_operator && toString(credit_limit_operator)) {
      params = {
        ...params,
        credit_limit_operator: credit_limit_operator.value,
      };
    }
    if (credit_limit_value && toString(credit_limit_value)) {
      params = {
        ...params,
        credit_limit_value: credit_limit_value.value,
      };
    }
    if (due_amount_value && toString(due_amount_value)) {
      params = {
        ...params,
        due_amount_value: due_amount_value.value,
      };
    }
    if (due_amount_operator && toString(due_amount_operator)) {
      params = {
        ...params,
        due_amount_operator: due_amount_operator.value,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getClientAccountsList(query);
      if (res.success) {
        setState((draft) => {
          draft.clientAccount.dataList = res.data;
          draft.initialLoad = false;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const HandleClientAccountStatus = async (id) => {
    try {
      const res = await EnableOrDisableClientAccount(id);
      if (res.success) {
        setState((draft) => {
          draft.clientAccount.dataList = draft.clientAccount.dataList.map(
            (v) => {
              return v.id === id ? { ...v, status: v.status === 1 ? 0 : 1 } : v;
            }
          );
        });
        successMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleDeleteClientAccount = async (type) => {
    try {
      const res = await deleteClientAccountById(state.clientAccount.id);
      if (res.success) {
        successMessage("Client account deleted successfully");
        setState((draft) => {
          draft.clientAccount.dataList = draft.clientAccount.dataList.filter(
            (item) => item.id !== state.clientAccount.id
          );
        });
        handleModal("client-ac-delete", false, null);
        handleModal("details", false, null);
        if (type === "details") {
          navigate(
            isStoreAdmin ? "/store-admin/client-account" : "/client-account"
          );
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleClientAccountStorsSelect = (id) => {
    const isSelcted = state.clientAccount.selectedItems.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.clientAccount.selectedItems =
          draft.clientAccount.selectedItems.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.clientAccount.selectedItems = [
          ...draft.clientAccount.selectedItems,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAssignCustomerstoClientAccount = async () => {
    const data = {
      customer_id: state.clientAccount.selectedItems,
    };

    try {
      const res = await assignCustomersToClinetAccount(storeId, data);
      if (true) {
        successMessage(res.message);
        setState((draft) => {
          draft.clientAccount.isLoading = true;
          return draft;
        });
        handleModal("customer-add", false);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    if (
      state.clientAccount.isLoading &&
      !state.clientAccount.initialLoad &&
      isList
    ) {
      handleClientAccountList();
    }
  }, [state.clientAccount.isLoading, isList]);

  const handleInputChange = (event) => {
    const name = event.target?.name;
    const value = event.target?.value;
    setState((draft) => {
      draft.settlement.data = {
        ...draft.settlement.data,
        [name]: value,
      };
    });
  };

  const onMakeSettlement = async (id) => {
    const data = {
      ...state.settlement.data,
      settlement_date: moment(state.settlement.data.settlement_date).format(
        "yyyy-MM-DD"
      ),
      amount:
        state.settlement.data.select_type === 0
          ? state.settlement.data.amount
          : state.purchaseAmount,
    };

    try {
      setState((draft) => {
        draft.settlement.isSaveButtonBusy = true;
      });
      const res = await createClinetAccountSettlement(id, data);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.settlement.isSaveButtonBusy = false;
        });
        navigate(`/store-admin/client-account/details/${id}`);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.settlement.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.settlement.isSaveButtonBusy = false;
      });
    }
  };

  const loadNextPage = () => {
    const { pageIndex, pageSize } = state.customers.pagination;
    getCustomerList({
      pagination: {
        pageIndex: pageIndex + 1,
        pageSize: pageSize,
      },
    });
    setState((draft) => {
      draft.customers.pagination.pageIndex =
        draft.customers.pagination.pageIndex + 1;
    });
  };

  const onSearchChange = (text) => {
    setState((draft) => {
      draft.customers.searchText = text;
    });
  };
  const onDateChange = (value) => {
    setState((draft) => {
      draft.data.date_of_birth = value || null;
    });
  };
  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onSaveCustomer = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        ...state.data,
        date_of_birth: moment(state.data.date_of_birth).format("YYYY-MM-DD"),
      };
      const res = await createCustomer(payload);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        handleAssignCustomerstoClientAccount([res.data.id]);
        navigate(
          isStoreAdmin
            ? `/store-admin/client-account/details/${res.data.id}`
            : `/client-account/details/${res.data.id}`
        );
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleFilterClear = (type, value) => {
    const {
      stores,
      searchText,
      status,
      credit_limit_operator,
      due_amount_operator,
      credit_limit_value,
      due_amount_value,
    } = state.filters;

    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filters.stores.filter(
          (e) => e.value !== value.value
        );
        handleClientAccountList(
          filteredStores,
          searchText,
          status,
          credit_limit_operator,
          due_amount_operator,
          credit_limit_value,
          due_amount_value
        );
        setState((draft) => {
          draft.filters.stores = filteredStores;
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: { ...state.filters, stores: filteredStores },
          },
        });
      } else if (value?.type === "Status") {
        handleClientAccountList(
          stores,
          searchText,
          "",
          credit_limit_operator,
          due_amount_operator,
          credit_limit_value,
          due_amount_value
        );
        setState((draft) => {
          draft.filters.status = "";
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: { ...state.filters, status: "" },
          },
        });
      } else if (value?.type === "credit_limit_operator") {
        handleClientAccountList(
          stores,
          searchText,
          status,
          "",
          due_amount_operator,
          credit_limit_value,
          due_amount_value
        );
        setState((draft) => {
          draft.filters.credit_limit_operator = "";
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: { ...state.filters, credit_limit_operator: "" },
          },
        });
      } else if (value?.type === "due_amount_operator") {
        handleClientAccountList(
          stores,
          searchText,
          status,
          credit_limit_operator,
          "",
          credit_limit_value,
          due_amount_value
        );
        setState((draft) => {
          draft.filters.due_amount_operator = "";
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: { ...state.filters, due_amount_operator: "" },
          },
        });
      } else if (value?.type === "credit_limit_value") {
        handleClientAccountList(
          stores,
          searchText,
          status,
          credit_limit_operator,
          due_amount_operator,
          "",
          due_amount_value
        );
        setState((draft) => {
          draft.filters.credit_limit_value = "";
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: { ...state.filters, credit_limit_value: "" },
          },
        });
      } else if (value?.type === "due_amount_value") {
        handleClientAccountList(
          stores,
          searchText,
          status,
          credit_limit_operator,
          due_amount_operator,
          credit_limit_value,
          ""
        );
        setState((draft) => {
          draft.filters.due_amount_value = "";
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: { ...state.filters, due_amount_value: "" },
          },
        });
      }
    } else {
      handleClientAccountList([], searchText, "", "", "", "", "");
      setState((draft) => {
        draft.filters.all = [];
        draft.filters.stores = [];
        draft.filters.status = "";
        draft.filters.credit_limit_operator = "";
        draft.filters.due_amount_operator = "";
        draft.filters.credit_limit_value = "";
        draft.filters.due_amount_value = "";
      });

      const initialValue = {
        isOpen: false,
        isSearching: false,
        all: [],
        searchText: "",
        stores: [],
        status: "",
        credit_limit_operator: "",
        due_amount_operator: "",
        credit_limit_value: "",
        due_amount_value: "",
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
    }
  };

  const debounced = useDebouncedCallback((value) => {
    const {
      stores,
      status,
      searchText,
      credit_limit_operator,
      due_amount_operator,
      credit_limit_value,
      due_amount_value,
    } = state.filters;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filters },
    });
    handleClientAccountList(
      stores,
      searchText,
      status,
      credit_limit_operator,
      due_amount_operator,
      credit_limit_value,
      due_amount_value);
  }, 1000);

  const handleSearch = (v) => {
    debounced(v);
    setState((draft) => {
      draft.filters.searchText = v;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters, searchText: v },
      },
    });

  };
  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.searchText = "";
      draft.filters.isSearching = true;
    });
    const {
      stores,
      status,
      credit_limit_operator,
      due_amount_operator,
      credit_limit_value,
      due_amount_value,
    } = state.filters;
    handleClientAccountList(
      stores,
      "",
      status,
      credit_limit_operator,
      due_amount_operator,
      credit_limit_value,
      due_amount_value
    );
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filters, searchText: "" } },
    });
  };
  const getClientAccountOnStore = async (storeId) => {
    const params = { store_id: [storeId] };
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getClientAccountsList(query);
      if (res.success) {
        setState((draft) => {
          draft.storeFilteredClientAccounts = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "status" || name === "restrict_usage") {
      setState((draft) => {
        draft.clientAccountDetails.updateData = {
          ...draft.clientAccountDetails.updateData,
          [name]: event.target.checked ? 1 : 0,
        };
      });
    } else if (name === "customer_id") {
      const filteredCustomers = value.filter((item) => item.custom === false);
      setState((draft) => {
        draft.clientAccountDetails.updateData = {
          ...draft.clientAccountDetails.updateData,
          [name]: filteredCustomers,
        };
      });
    } else if (name === "store_id") {
      setState((draft) => {
        draft.clientAccountDetails.updateData.customer_id = [];
      });
      setState((draft) => {
        draft.clientAccountDetails.updateData = {
          ...draft.clientAccountDetails.updateData,
          [name]: value,
        };
      });
    } else {
      setState((draft) => {
        draft.clientAccountDetails.updateData = {
          ...draft.clientAccountDetails.updateData,
          [name]: value,
        };
      });
    }
  };
  const onDayPicker = (value) => {
    if (state.clientAccountDetails.updateData.bill_day === value) {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_day = null;
      });
    } else {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_day = value;
      });
    }
  };
  const onCreateClientAccount = async (type) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      let data = {
        store_id: isStoreAdmin
          ? auth.data.selectedStoreId
          : state.clientAccountDetails.updateData.store_id,
        status: state.clientAccountDetails.updateData.status,
        restrict_usage: state.clientAccountDetails.updateData.restrict_usage,
        due_amount: state.clientAccountDetails.updateData.due_amount,
        credit_limit: state.clientAccountDetails.updateData.credit_limit,
        carry_forward: state.clientAccountDetails.updateData.carry_forward,
        bill_duration: state.clientAccountDetails.updateData.bill_duration,
        bill_period: state.clientAccountDetails.updateData.bill_period,
        customer_id: isCustomer
          ? [id]
          : state.clientAccountDetails.updateData.customer_id.map(
            (v) => v.value
          ),
      };

      if (state.clientAccountDetails.updateData.bill_period === 1) {
        data = {
          ...data,
          bill_date: null,
          bill_day: state.clientAccountDetails.updateData.bill_day,
        };
      } else if (state.clientAccountDetails.updateData.bill_period === 3) {
        data = {
          ...data,
          bill_period: state.clientAccountDetails.updateData.bill_period,
          bill_duration: state.clientAccountDetails.updateData.bill_duration,
          bill_date: null,
          bill_day: null,
        };
      } else if (state.clientAccountDetails.updateData.bill_period === 2) {
        if (value === 1) {
          data = {
            ...data,
            bill_date: state.clientAccountDetails.updateData.bill_date,
            bill_date2: null,
            bill_day: null,
          };
        } else {
          data = {
            ...data,
            bill_date: state.clientAccountDetails.updateData.bill_date2,
            bill_date2: null,
            bill_day: state.clientAccountDetails.updateData.bill_day,
          };
        }
      }
      const res = await createClientAccount(data);
      if (res.success) {
        successMessage("Created Successfully");
        isCustomer
          ? navigate(
            isStoreAdmin
              ? `/store-admin/details/customers/${id}`
              : `/customers/details/${id}`
          )
          : navigate(
            isStoreAdmin ? `/store-admin/client-account` : `/client-account`
          );
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const onChangeFilter = (e) => {
    setState((draft) => {
      draft.filters.searchText = e.target.value;
      draft.filters.isSearching = false;
    });
    debounced(e.target.value);
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters, searchText: e.target.value },
      },
    });
  };
  const handleApplyFilter = () => {
    const {
      stores,
      searchText,
      status,
      credit_limit_operator,
      due_amount_operator,
      credit_limit_value,
      due_amount_value,
    } = state.filters;
    handleClientAccountList(
      stores,
      searchText,
      status,
      credit_limit_operator,
      due_amount_operator,
      credit_limit_value,
      due_amount_value
    );
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters },
      },
    });
  };
  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filters.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "status") {
      setState((draft) => {
        draft.filters.status = {
          value: value.props.value,
          label: value.props.children,
          type: "Status",
        };
      });
    } else if (type === "credit_limit_operator") {
      setState((draft) => {
        draft.filters.credit_limit_operator = {
          value: value?.props?.value,
          label: value?.props?.children,
          type: "credit_limit_operator",
        };
      });
    } else if (type === "due_amount_operator") {
      setState((draft) => {
        draft.filters.due_amount_operator = {
          value: value?.props?.value,
          label: value?.props?.children,
          type: "due_amount_operator",
        };
      });
    } else if (type === "credit_limit_value") {
      setState((draft) => {
        draft.filters.credit_limit_value = {
          value: e,
          label: e,
          type: "credit_limit_value",
        };
      });
    } else if (type === "due_amount_value") {
      setState((draft) => {
        draft.filters.due_amount_value = {
          value: e,
          label: e,
          type: "due_amount_value",
        };
      });
    }
  };

  useEffect(() => {
    let allData = [
      ...state.filters.stores,
      state.filters.status,
      state.filters.credit_limit_operator,
      state.filters.credit_limit_value,
      state.filters.due_amount_operator,
      state.filters.due_amount_value,
    ].filter((el) => Object.keys(el).length);
    setState((draft) => {
      draft.filters.all = allData
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters, all: allData },
      },
    });
  }, [
    state.filters.credit_limit_operator,
    state.filters.stores,
    state.filters.status,
    state.filters.due_amount_operator,
    state.filters.credit_limit_value,
    state.filters.due_amount_value,
  ]);

  const handleGetCustomerList = async ({
    search = null,
    page,
    loadedOptions,
  }) => {
    let params = {};

    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };

    if (search) {
      params = { ...params, search };
    }

    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };

    try {
      const res = await getCustomersList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.customers.optionsList = res.data.customers.map((data) => {
            return { value: JSON.stringify(data.id), label: data.name };
          });
          draft.customers.isBusy = false;
        });

        const options = res.data.customers.map((data) => {
          return {
            value: JSON.stringify(data.id),
            label: data.name,
            email: data.email,
            phone: data.phone_formatted,
            custom: false,
          };
        });
        if (!loadedOptions.length) options.unshift({ custom: true });
        return {
          options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const onDeleteCustomerSelect = (data) => {
    setState((draft) => {
      draft.clientAccountDetails.updateData.customer_id = [
        ...state.clientAccountDetails.updateData.customer_id.filter((value) => {
          return value !== data;
        }),
      ];
    });
  };
  const HandleClientAccountDetails = async (settlementId) => {
    try {
      const res = await getClientAccountDetailsById(settlementId);
      if (res.success) {
        setState((draft) => {
          draft.settlement.data.amount = Number(res.data.due_amount);
          draft.amount = Number(res.data.due_amount);
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.clientAccountDetails.isLoading = false;
      });
    }
  };
  useEffect(() => {
    if (settlementId) {
      HandleClientAccountDetails(settlementId);
    }
  }, [settlementId]);
  const onSwitchChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setState((draft) => {
      draft.settlement.data.select_type = value;
    });
  };
  const toggleViewModal = (type) => {
    setState((draft) => {
      draft.isViewOpen = type;
    });
  };

  const getPurchasedDataList = async (settlementId) => {
    try {
      const res = await getPurchasedData(settlementId);
      if (res.success) {
        setState((draft) => {
          draft.purchasedData = res.data;
        });
      }
    } catch (err) { }
  };
  useEffect(() => {
    if (settlementId) {
      getPurchasedDataList(settlementId);
    }
  }, [settlementId]);
  const handleChecked = (id) => {
    return state.settlement.data.transactions.includes(id);
  };
  const handleAllChecked = () => {
    const isAll = state.purchasedData.map((i) => i.id);

    return (
      isAll.length > 0 &&
      isAll.every((val) => state.settlement.data.transactions.includes(val))
    );
  };
  const handleCheckindeterminate = () => {
    const isAll = state.purchasedData.map((i) => i.id);
    const commonIDs = state.settlement.data.transactions?.filter((val) =>
      isAll.includes(val)
    );
    const notAllIDs = isAll.every((val) =>
      state.settlement.data.transactions.includes(val)
    );
    return commonIDs.length > 0 && !notAllIDs;
  };
  const handleSelectedIds = (e, row) => {
    if (e.target.checked) {
      setState((draft) => {
        draft.settlement.data.transactions = [
          ...draft.settlement.data.transactions,
          row.original.id,
        ];
      });
      setState((draft) => {
        draft.filterData = [...draft.filterData, row.original];
      });
    } else {
      setState((draft) => {
        draft.settlement.data.transactions = [
          ...draft.settlement.data.transactions.filter(
            (v) => v !== row.original.id
          ),
        ];
      });
      setState((draft) => {
        draft.filterData = [
          ...draft.filterData.filter((v) => v.id !== row.original.id),
        ];
      });
    }
  };

  const handleAllSelectedIds = (e, row) => {
    const isAll = state.purchasedData.map((i) => i.id);
    const isAllData = state.purchasedData.map((i) => i);
    if (e.target.checked) {
      setState((draft) => {
        draft.settlement.data.transactions = isAll;
        draft.filterData = isAllData;
      });
    } else {
      setState((draft) => {
        draft.settlement.data.transactions = [];
        draft.filterData = [];
      });
    }
  };

  useEffect(() => {
    if (state.filterData) {
      const purchaseAmount = state.filterData.reduce(
        (a, v) => (a = a + v.balance),
        0
      );
      setState((draft) => {
        draft.purchaseAmount = purchaseAmount;
      });
    }
  }, [state.filterData]);
  return {
    state,
    globalData,
    isEditable,
    isDeletable,
    isCreateVisible,
    currentTab,
    setCurentTab,
    handleModal,
    HandleClientAccountStatus,
    handleDeleteClientAccount,
    handleClientAccountStorsSelect,
    handleAssignCustomerstoClientAccount,
    handleInputChange,
    onMakeSettlement,
    loadNextPage,
    onSearchChange,
    onChange,
    onDateChange,
    onSaveCustomer,
    isStoreAdmin,
    handleFilterClear,
    handleSearch,
    onSeachClear,
    handleChange,
    onDayPicker,
    onCreateClientAccount,
    onChangeFilter,
    handleApplyFilter,
    handleFilter,
    handleGetCustomerList,
    onDeleteCustomerSelect,
    value,
    setValue,
    onSwitchChange,
    toggleViewModal,
    handleChecked,
    handleCheckindeterminate,
    handleSelectedIds,
    handleAllChecked,
    handleAllSelectedIds,
  };
};
